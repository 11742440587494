<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                业绩信息
            </div>
            <van-field
                    readonly
                    disabled
                    clickable
                    name="datetimePicker"
                    :value="orderdate"
                    label="下单日期"
                    placeholder="点击选择时期"
                    @click="showPicker1 = true"
            />
            <van-popup v-model="showPicker1" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onSJConfirm"
                        @cancel="showPicker1 = false"
                />
            </van-popup>
            <van-field
                    v-model="xyname"
                    disabled
                    name="学员姓名"
                    label="学员姓名"
                    placeholder="学员姓名"
                    :rules="[{ required: true, message: '请填写学员姓名' }]"
            />
            <van-field name="radio" label="性别">
                <template #input>
                    <van-radio-group v-model="xysex" disabled direction="horizontal">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                    v-model="xypname"
                    disabled
                    name="监护人"
                    label="监护人"
                    placeholder="监护人"
            />
            <van-field
                    v-model="xyphone"
                    name="手机号"
                    disabled
                    label="手机号"
                    placeholder="手机号"
                    type="digit"
                    :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field
                    v-model="ccount"
                    name="课次"
                    style="color:purple"
                    label="课次"
                    placeholder="课次"
                    :rules="[{ required: true, message: '请填写课次' }]"
            />
            <van-field
                    v-model="cprice"
                    name="金额"
                    style="color:purple"
                    label="金额"
                    type="digit"
                    placeholder="金额"
                    :rules="[{ required: true, message: '请填写金额' }]"
            />
            <van-field
                    readonly
                    clickable
                    style="color:purple"
                    name="picker"
                    :value="payway"
                    label="支付方式"
                    placeholder="点击选择支付方式"
                    @click="showPicker2 = true"
            />
            <van-popup v-model="showPicker2" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="paywaylist"
                        @confirm="onpaywayConfirm"
                        @cancel="showPicker2 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    style="color:purple"
                    name="picker"
                    :value="xq"
                    label="校区"
                    placeholder="点击选择校区"
                    @click="showPicker3 = true"
            />
            <van-popup v-model="showPicker3" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="xqlist"
                        @confirm="onxqConfirm"
                        @cancel="showPicker3 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    style="color:purple"
                    name="picker"
                    :value="jl"
                    label="教练"
                    placeholder="点击选择教练"
                    @click="showPicker4 = true"
            />
            <van-popup v-model="showPicker4" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="jllist"
                        @confirm="onjlConfirm"
                        @cancel="showPicker4 = false"
                />
            </van-popup>
            <van-field
                    v-model="jxrname"
                    name="介绍人"
                    label="介绍人"
                    placeholder="介绍人"
                    :rules="[{ required: true, message: '请填写介绍人' }]"
            />
            <van-field
                    readonly
                    clickable
                    style="color:purple"
                    name="picker"
                    :value="kc"
                    label="套餐类型"
                    placeholder="点击选择续费课程类型"
                    @click="showPicker12 = true"
            />
            <van-popup v-model="showPicker12" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="kclist"
                        @confirm="onkcConfirm"
                        @cancel="showPicker12 = false"
                />
            </van-popup>
            <van-field label="拍照凭证">
                <template #input>
                    <viewer :images="photo" >
                        <img
                                style="width:220px;height:220px;"
                                v-for="(src,index) in photo"
                                :src="src"
                                :key="index"
                        >
                    </viewer>
                </template>
            </van-field>
            <van-field name="uploader" label="重新上传">
                <template #input>
                    <van-uploader v-model="fileList" accept="image/*" multiple  :after-read="fnUploadRequest"/>
                </template>
            </van-field>
            <van-progress v-if="percentageType == true" :percentage="percentage" color="#3fb776" style="margin-left: 90px; margin-right:20px; margin-top: 5px; margin-bottom: 5px;"/>
            <van-field
                    style="color:purple"
                    v-model="pzimgs"
                    name="凭证地址"
                    label="凭证地址"
                    placeholder="凭证地址"
            />
            <van-field
                    v-model="fkdes"
                    style="color:purple"
                    rows="3"
                    autosize
                    label="付款单号"
                    type="textarea"
                    show-word-limit
                    placeholder="付款单号后6位(必填)"
            />
            <div class="van-doc-demo-block__title">
                队服信息
            </div>
            <!--
            <van-field
                    v-model="fzno"
                    name="号码"
                    label="号码"
                    type="digit"
                    placeholder="请输入队服号码"
            />
            -->
            <van-field
                    readonly
                    clickable
                    name="picker"
                    style="color:purple"
                    :value="fzcm"
                    label="队服尺码"
                    placeholder="点击选择队服尺码"
                    @click="showPicker10 = true"
            />
            <van-popup v-model="showPicker10" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="cmlist"
                        @confirm="oncmConfirm"
                        @cancel="showPicker10 = false"
                />
            </van-popup>
            <!--
            <van-field
                    v-model="fzsg"
                    name="身高"
                    label="身高"
                    type="digit"
                    placeholder="请输入队员身高"
            />
            <van-field
                    v-model="xyage"
                    name="年龄"
                    label="年龄"
                    type="digit"
                    placeholder="请输入队员年龄"
            />
            -->
            <van-field
                    name="stepper"
                    style="color:purple"
                    label="数量"
                    placeholder="请输入数量"
            >
                <template #input>
                    <van-stepper v-model="fzcount" />
                </template>
            </van-field>
            <div style="margin: 16px;margin-bottom: 20px;">
                <van-button round block type="info" native-type="submit" @click="saveorder">
                    保存修改
                </van-button>
                <van-button round block type="danger" style="margin-top:10px;" native-type="submit" @click="delmemberrec">
                    删除订单
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Progress } from 'vant';
    import { Stepper } from 'vant';

    Vue.use(Stepper);
    var OSS = require('ali-oss')
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast);
    Vue.use(Progress);
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "ShowXD",
        data () {
            return {
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                showPicker5: false,
                showPicker12: false,
                fzcount: 1,
                photo: '',
                dfcount: 1,
                selectpath: 'od/pz',
                xq: '',
                jl: '',
                xyname: '',
                region: 'cn-beijing',
                fkdes: '',
                xysex: '1',
                xypname: '',
                jxrname: '',
                pzimgs: '',
                xyphone: '',
                kc: '',
                kcid: '',
                kclist: [],
                kcidlist: [],
                xytype: '',
                showPicker10: false,
                cmlist: [
                    'XS',
                    'S',
                    'M',
                    'L',
                    'XL',
                    'XXL',
                    'XXXL'
                ],
                ccount: '',
                cprice: '',
                payway: '',
                currentDate: '',
                orderdate: '',
                xqlist: [],
                xqid: '',
                xqidlist: [],
                percentageType: false,
                jllist: [],
                jlid: '',
                fzno: '',
                fzsg: '',
                xyage: '',
                fzcm: '',
                jlidlist: [],
                fileIds: [],
                selid: '',
                fileList: [],
                upfileList: [],
                paywaylist: [
                    '微信',
                    '支付宝',
                    '考拉',
                    'POS',
                    '转账'
                ],
                paywayid: '',
                khtypelist: [],
                khtypeidlist: [],
                khtypeid: '',
                percentage: 0,
                fileurls: [],
                selorder: ''
            }
        },
        mounted: function () {
            that = this
            window.console.log(that)
            that.currentDate = new Date()
            var tmpdata = JSON.parse(localStorage.getItem('sorderinfo'))
            that.selorder = tmpdata
            this.xyname = tmpdata.xyname
            this.xysex = tmpdata.xysex.toString()
            this.orderdate = tmpdata.orderdate
            this.xypname = tmpdata.xypname
            this.xyphone = tmpdata.xyphone
            this.ccount = tmpdata.ccount
            this.cprice = tmpdata.cprice
            this.payway = tmpdata.payway
            this.jxrname = tmpdata.jxrname
            this.photo = tmpdata.fkimg.split(',')
            this.pzimgs = tmpdata.fkimg.split(',')
            this.fkdes = tmpdata.fkdes
            this.selid = tmpdata.id
            if(tmpdata.fzno === 0){
                this.fzno = ''
            } else {
                this.fzno = tmpdata.fzno
            }
            this.fzcm = tmpdata.fzcm
            if(tmpdata.fzcount === 0){
                this.fzcount = ''
            } else {
                this.fzcount = tmpdata.fzcount
            }
            if(tmpdata.fzsg === 0){
                this.fzsg = ''
            } else {
                this.fzsg = tmpdata.fzsg
            }
            if(tmpdata.xyage === 0){
                this.xyage = ''
            } else {
                this.xyage = tmpdata.xyage
            }
            that = this
            that.getxqlist(tmpdata.xqid)
            that.getjllist(tmpdata.jlid)
            that.getmtypelist(tmpdata.xytype)
            document.title = '业绩修改'
        },
        methods: {
            onkcConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker12 = false
                that.kc = value
                that.kcid = that.kcidlist[index]
            },
            oncmConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker10 = false
                that.fzcm = value
            },
            getmtypelist (typeid) {
                var qstr = 'id >= 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/querymembertype',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.kclist.push(tmpdata[i].mtype)
                                that.kcidlist.push(tmpdata[i].id)
                                if (typeid.toString() === tmpdata[i].id.toString()) {
                                    that.kc = tmpdata[i].mtype
                                    that.kcid = tmpdata[i].id
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxqlist (index) {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.xqlist.push(tmpdata[i].xqname)
                                that.xqidlist.push(tmpdata[i].id)
                                if (index === tmpdata[i].id) {
                                    that.xq = tmpdata[i].xqname
                                    that.xqid = tmpdata[i].id
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getjllist (index) {
                var qstr = "userid > 0 and postname like '%教练%'"
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryjlinfo',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.jllist.push(tmpdata[i].realname)
                                that.jlidlist.push(tmpdata[i].userid)
                                if (index === tmpdata[i].userid) {
                                    that.jl = tmpdata[i].realname
                                    that.jlid = tmpdata[i].userid
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            onxqConfirm (value,index) {
                window.console.log(value)
                that.xq = that.xqlist[index]
                window.console.log(that.xq)
                that.xqid = that.xqidlist[index]
                that.showPicker3 = false
            },
            onSJConfirm (date) {
                this.orderdate = this.formatDate(date);
                this.showPicker1 = false;
            },
            onpaywayConfirm (value,index) {
                window.console.log(value)
                that.payway = that.paywaylist[index]
                window.console.log(that.payway)
                that.paywayid = parseInt(index) + 1
                that.showPicker2 = false
            },
            onjlConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.jl = that.jllist[index]
                window.console.log(that.jl)
                that.jlid = that.jlidlist[index]
                that.showPicker4 = false
            },
            onkhtypeConfirm (value,index) {
                window.console.log(value)
                that.xytype = that.khtypelist[index]
                window.console.log(that.xytype)
                that.khtypeid = that.khtypeidlist[index]
                that.showPicker5 = false
            },
            saveorder () {
                window.console.log(that.fileIds)
                window.console.log(that.selorder.xysex)
                window.console.log(that.ccount)
                window.console.log(that.cprice)
                window.console.log(that.payway)
                window.console.log(that.jlid)
                window.console.log(that.fzno)
                window.console.log(that.fzsg)
                window.console.log(that.xyage)
                window.console.log(that.fzcount)
                window.console.log(that.fzcm)
                if (that.selorder.xyid === null) {
                    that.selorder.xyid = ''
                }
                var adddata = {
                    xyname: that.selorder.xyname,
                    xysex: that.selorder.xysex.toString(),
                    xypname: that.selorder.xypname,
                    xyphone: that.selorder.xyphone,
                    ccount: that.ccount.toString(),
                    cprice: that.cprice.toString(),
                    payway: that.payway.toString(),
                    tdid: that.selorder.tdid.toString(),
                    xqid: that.xqid.toString(),
                    jlid: that.jlid.toString(),
                    jxrname: that.jxrname,
                    xytype: that.kcid.toString(),
                    fkimg: that.pzimgs.join(',').toString(),
                    fkdes: that.fkdes,
                    orderdate: that.orderdate,
                    orderstate: '1',
                    adduser: localStorage.getItem('loginid'),
                    adddate: getNowFormatDate(),
                    rquser: '',
                    rqdate: '',
                    fzno: '',
                    fzsg: '',
                    xyage: '',
                    fzcount: that.fzcount.toString(),
                    fzcm: that.fzcm.toString(),
                    xyid: that.selorder.xyid.toString(),
                    xftype: that.kcid.toString()
                }
                window.console.log(adddata)
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/addorder',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            Toast('修改完毕!');
                            that.$router.go(-1)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            delorder () {
                var delrec = {
                    phone: that.selorder.xyphone,
                    orderdate: that.selorder.orderdate
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/delorder',
                    method: 'post',
                    data: delrec,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            that.$router.go(-1)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            delmemberrec () {
                var delrec = {
                    memberid: that.selorder.xyid.toString(),
                    startdate: that.selorder.orderdate
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/delmcount',
                    method: 'post',
                    data: delrec,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            that.delorder()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAI5t9oHyNgxdDwTw4mnHLj',
                        accessKeySecret: 'lFA3kYzgnv7BiAmMVk7NNus7RBcnox',
                        bucket: 'sqpro'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            async fnUploadRequest (option) {
                var that = this
                if (option.file) {
                    window.console.log('单文件')
                    let file = option.file
                    const self = this
                    that.fileList[0].status = 'uploading'
                    that.fileList[0].message = '上传中'
                    this.percentageType = true;
                    return new Promise((resolve, reject) => {
                        let fileName = that.selectpath + '/' + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        window.console.log(fileName)
                        self.createOssClient().then(client => {
                            // 异步上传,返回数据
                            resolve({
                                fileName: file.name,
                                fileUrl: fileName
                            })
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    window.console.log(e.percent)
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    window.console.log(that.upfileList)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.pzimgs = that.fileurls.join(',').toString()
                                    that.fileList[0].status = 'done'
                                    that.fileList[0].message = '上传完成'
                                    return val
                                } else {
                                    that.fileList[0].status = 'failed'
                                    that.fileList[0].message = '上传失败'
                                }
                            }, err => {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                window.console.log(err)
                                window.console.log(reject)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    })
                } else {
                    window.console.log('多文件')
                    for (var i = 0; i < option.length; i++ ) {
                        window.console.log(option)
                        let file = option[i].file
                        window.console.log(that.fileList.length - option.length)
                        that.fileList[i].status = 'uploading'
                        that.fileList[i].message = '上传中'
                        const self = this
                        this.percentageType = true;
                        file.index = i
                        let fileName = that.selectpath + '/' + i + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        self.createOssClient().then(client => {
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    if (e.percent === 100) {
                                        that.fileList[file.index].status = 'success'
                                        that.fileList[file.index].message = '上传完毕'
                                    }
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.pzimgs = that.fileurls.join(',').toString()
                                    window.console.log(that.upfileList)
                                    return val
                                } else {
                                    window.console.log('上传失败')
                                }
                            }, err => {
                                window.console.log(err)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    }
                }

            }
        }
    }
    function getNowFormatDate () {
        var date = new Date()
        var seperator1 = '-'
        var seperator2 = ':'
        var month = date.getMonth() + 1
        var strDate = date.getDate()
        if (month >= 1 && month <= 9) {
            month = '0' + month
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate
        }
        var hours = date.getHours()
        if (hours >= 0 && hours <= 9) {
            hours = '0' + hours
        }
        var minutes = date.getMinutes()
        if (minutes >= 0 && minutes <= 9) {
            minutes = '0' + minutes
        }
        var seconds = date.getSeconds()
        if (seconds >= 0 && seconds <= 9) {
            seconds = '0' + seconds
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' ' + hours + seperator2 + minutes + seperator2 + seconds
        return currentdate
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
