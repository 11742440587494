<template>
    <div class="contextcontainer" style="margin-top: 2px">
        <div v-for="(item, index) in course_listdata" :key="index">
            <div v-if="course_listdata !== '[]'">
                <div v-if="index % 2 == 1">
                    <div :style="'width:' + colwidth + 'px;'">
                        <div class="txtcontainer">
                            <div class="imageview">
                                <img class="imginfo" v-lazy="item.cimgurl.String" />
                            </div>
                            <div class="txtview">
                                <div class="txtinfo">{{item.ctitle.String}}</div>
                            </div>
                            <div class="txtview">
                                <div v-if="!item.ctitle.String || item.price.String == '0' || item.price.String == ''">
                                    <div class="redpricetxtinfo">免费</div>
                                </div>
                                <div v-else>
                                    <div class="huobitxtinfo">￥</div>{{item.price.String}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rowslip"></div>
                </div>
                <div v-else>
                    <div :style="'width:' + colwidth + 'px;'">
                        <div class="txtcontainer" style="border-right: 1px #ccc solid">
                            <div class="imageview">
                                <img class="imginfo" v-lazy="item.cimgurl.String" />
                            </div>
                            <div class="txtview">
                                <div class="txtinfo">{{item.ctitle.String}}</div>
                            </div>
                            <div class="txtview">
                                <div v-if="!item.ctitle.String || item.price.String == '0' || item.price.String == ''">
                                    <div class="redpricetxtinfo">免费</div>
                                </div>
                                <div v-else>
                                    <div class="huobitxtinfo">￥</div>{{item.price.String}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rowslip"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'CourseList',
        data () {
            return {
                course_listdata: [],
                colwidth: document.body.clientWidth / 2
            }
        },
        mounted: function () {
            that = this
            window.onresize = () => {
                return (() => {
                    that.colwidth = document.body.clientWidth / 2
                })()
            }
            that.$options.methods.getcourselist()
        },
        methods: {
            getcourselist: function () {
                var reloadstr = 'cusid = 1 and state = 1'
                that.$http({
                    url: 'https://dfsd.sqnetsoft.cn/course/qci',
                    method: 'post',
                    data: {
                        whereinfo: { String: reloadstr, Valid: true }
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.course_listdata = JSON.parse(res.data.data)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            }
        }
    }
</script>