/**
 * Created by yangxiaoshuo on 2019/10/15.
 */
import Vue from 'vue'
import Router from 'vue-router'
import MainPage from './components/MainPage.vue'
import Information from './components/Information.vue'
import Mana from './components/Mana.vue'
import My from './components/My.vue'
import Type from './components/Type.vue'
import CourseList from './components/CourseList.vue'
import CourseInfo from './components/CourseInfo.vue'
import SharePage from './components/SharePage.vue'
import MyCourse from './components/MyCourse.vue'
import Regedit from './components/Regedit.vue'
import MyHoliday from './components/MyHoliday.vue'
import MyPJ from './components/MyPJ.vue'
import MyJY from './components/MyJY.vue'
import MyUpLoad from './components/MYUpLoad.vue'
import MyMsg from './components/MyMsg.vue'
import MyHomeWork from './components/MyHomeWork.vue'
import ShowXD from './components/ShowXD.vue'
import ShowXD1 from './components/ShowXD1.vue'
import XDSH from './components/XDSH.vue'
import QueryXD from './components/QueryXD.vue'
import TJXD from './components/TJXD.vue'
import ShowOrder from './components/ShowOrder.vue'
import CourseAdd from './components/CourseAdd.vue'
import CourseAdd1 from './components/CourseAdd1.vue'
import CourseQR from './components/CourseQR.vue'
import QueryCourse from './components/QueryCourse.vue'
import TJCourse from './components/TJCourse.vue'
import SFWay from './components/SFWay.vue'
import RegeditUser from './components/RegeditUser.vue'
import Login from './components/Login.vue'
import MyXD from './components/MyXD.vue'
import MyXD1 from './components/MyXD1.vue'
import ShowOrderNoSH from './components/ShowOrderNoSH.vue'
import ShowXD_Edit from './components/ShowXD_Edit.vue'
import ShowXD_Edit1 from './components/ShowXD_Edit1.vue'
import ChangePwd from './components/ChangePwd.vue'
import MyKS from './components/MyKS.vue'
import MyKS1 from './components/MyKS1.vue'
import ShowKsOrderNoSH from './components/ShowKsOrderNoSH.vue'
import ShowKS_Edit from './components/ShowKS_Edit.vue'
import ShowKS_Edit1 from './components/ShowKS_Edit1.vue'
import ShowKS_SH from './components/ShowKS_SH.vue'
import ShowKS_SH1 from './components/ShowKS_SH1.vue'
import BZMana from './components/BZMana.vue'
import YSXK from './components/YSXK.vue'
import BZFP from './components/BZFP.vue'
Vue.use(Router)

export default new Router ({
    routes: [
        {path:'/',name:'OD管理平台',meta:{title: 'OD管理平台'},component:MainPage},
        {path:'/MainPage',name:'MainPage',component:MainPage},
        {path:'/BZMana',name:'BZMana',component:BZMana},
        {path:'/YSXK',name:'YSXK',component:YSXK},
        {path:'/BZFP',name:'BZFP',component:BZFP},
        {path:'/ShowKsOrderNoSH',name:'ShowKsOrderNoSH',component:ShowKsOrderNoSH},
        {path:'/ShowKS_Edit',name:'ShowKS_Edit',component:ShowKS_Edit},
        {path:'/ShowKS_Edit1',name:'ShowKS_Edit1',component:ShowKS_Edit1},
        {path:'/Information',name:'Information',component:Information},
        {path:'/MyKS',name:'MyKS',component:MyKS},
        {path:'/MyKS1',name:'MyKS1',component:MyKS1},
        {path:'/ShowXD',name:'ShowXD',component:ShowXD},
        {path:'/ShowXD1',name:'ShowXD1',component:ShowXD1},
        {path:'/ShowXD_Edit',name:'ShowXD_Edit',component:ShowXD_Edit},
        {path:'/ShowXD_Edit1',name:'ShowXD_Edit1',component:ShowXD_Edit1},
        {path:'/ShowKS_SH',name:'ShowKS_SH',component:ShowKS_SH},
        {path:'/ShowKS_SH1',name:'ShowKS_SH1',component:ShowKS_SH1},
        {path:'/SFWay',name:'SFWay',component:SFWay},
        {path:'/CourseAdd',name:'CourseAdd',component:CourseAdd},
        {path:'/CourseAdd1',name:'CourseAdd1',component:CourseAdd1},
        {path:'/CourseQR',name:'CourseQR',component:CourseQR},
        {path:'/QueryCourse',name:'QueryCourse',component:QueryCourse},
        {path:'/TJCourse',name:'TJCourse',component:TJCourse},
        {path:'/XDSH',name:'XDSH',component:XDSH},
        {path:'/QueryXD',name:'QueryXD',component:QueryXD},
        {path:'/TJXD',name:'TJXD',component:TJXD},
        {path:'/ShowOrder',name:'ShowOrder',component:ShowOrder},
        {path:'/Mana',name:'Mana',component:Mana},
        {path:'/My',name:'My',meta:{title: '我的'},component:My},
        {path:'/Type',name:'Type',component:Type},
        {path:'/Login',name:'Login',component:Login},
        {path:'/CourseInfo',name:'CourseInfo',component:CourseInfo},
        {path:'/MyCourse',name:'MyCourse',meta:{title: '课程安排'},component:MyCourse},
        {path:'/CourseList',name:'CourseList',component:CourseList},
        {path:'/SharePage',name:'SharePage',meta:{title: '我的分享'},component:SharePage},
        {path:'/Regedit',name:'Regedit',meta:{title: '讲师注册'},component:Regedit},
        {path:'/RegeditUser',name:'RegeditUser',meta:{title: '用户注册'},component:RegeditUser},
        {path:'/MyHoliday',name:'MyHoliday',meta:{title: '请假管理'},component:MyHoliday},
        {path:'/MyPJ',name:'MyPJ',meta:{title: '评价管理'},component:MyPJ},
        {path:'/MyJY',name:'MyJY',meta:{title: '建议管理'},component:MyJY},
        {path:'/MyHomeWork',name:'MyHomeWork',meta:{title: '我的作业'},component:MyHomeWork},
        {path:'/MyUpLoad',name:'MyUpLoad',component:MyUpLoad},
        {path:'/MyMsg',name:'MyMsg',component:MyMsg},
        {path:'/MyXD',name:'我的业绩',component:MyXD},
        {path:'/MyXD1',name:'我的业绩',component:MyXD1},
        {path:'/ShowOrderNoSH',name:'业绩信息',component:ShowOrderNoSH},
        {path:'/ChangePwd',name:'修改密码',component:ChangePwd}
    ]
})
