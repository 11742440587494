<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                课时审核
            </div>
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="sjstartvalue"
                    label="开始时间"
                    placeholder="点击选择时间"
                    @click="showPicker1 = true"
            />
            <van-popup v-model="showPicker1" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onSJConfirm"
                        @cancel="showPicker1 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="sjendvalue"
                    label="结束时间"
                    placeholder="点击结束时间"
                    @click="showPicker2 = true"
            />
            <van-popup v-model="showPicker2" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onSJEndConfirm"
                        @cancel="showPicker2 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="jl"
                    label="任课教练"
                    placeholder="点击选择教练"
                    @click="showPicker4 = true"
            />
            <van-popup v-model="showPicker4" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="jllist"
                        @confirm="onjlConfirm"
                        @cancel="showPicker4 = false"
                />
            </van-popup>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" @click="getxl">
                    查询课时
                </van-button>
            </div>
            <div class="van-doc-demo-block__title">
                课时汇总
            </div>
            <div style="padding: 10px;" id="mymainChart" v-loading="loading" class="chartcard">
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    var myChart
    export default {
        name: "QueryCourse",
        data () {
            return {
                loading: false,
                sjstartvalue: '',
                sjendvalue: '',
                currentDate: '',
                jl: '',
                jllist: [],
                jlidlist: [],
                showPicker4: false,
                showPicker1: false,
                showPicker2: false
            }
        },
        mounted() {
            that = this
            window.console.log(that)
            that.currentDate = new Date()
            // that.getjllist()
        },
        methods: {
            onSJConfirm (date) {
                this.sjstartvalue = this.formatDate(date);
                this.showPicker1 = false;
            },
            onSJEndConfirm (date) {
                this.sjendvalue = this.formatDate(date);
                this.showPicker2 = false;
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            onjlConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.jl = that.jllist[index]
                window.console.log(that.jl)
                window.console.log(that.jlidlist)
                that.jlid = that.jlidlist[index]
                that.showPicker4 = false
            },
            getjllist () {
                var qstr = "userid > 0 and postname like '%教练%'"
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryjlinfo',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.jllist.push(tmpdata[i].realname)
                                that.jlidlist.push(tmpdata[i].userid)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxl () {
                var qstr = ' jlid > 0'
                if (this.sjstartvalue !== null && this.sjstartvalue !== '' && this.sjendvalue !== null && this.sjendvalue !== '') {
                    qstr = qstr + " and orderdate >= '" + this.sjstartvalue + " 00:00:00' and orderdate<='" + this.sjendvalue + " 23:59:59'"
                }
                if (that.jl !== null && that.jl !== '' && that.jl !== undefined) {
                    qstr = qstr + ' and jlid=' + this.jlid
                }
                var qdata = {
                    whereinfo: qstr,
                    limit: '',
                    sortinfo: ''
                }

                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryksorderbydate',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        var tmpdata = res.data
                        var xllist = []
                        var xsjelist = []
                        for (var i = 0;i < tmpdata.length; i++) {
                            if (tmpdata[i].kctype !== null) {
                                xllist.push(tmpdata[i].kctype)
                                xsjelist.push(tmpdata[i].kccount)
                            }
                        }
                        window.console.log(xllist)
                        window.console.log(xsjelist)
                        window.console.log('重新绘制')
                        that.drawLine(xllist,xsjelist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            drawLine (xllist,xsjelist) {
                // 基于准备好的dom，初始化echarts实例
                myChart = this.$echarts.init(document.getElementById('mymainChart'))
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '课时',
                        textStyle:{
                            fontSize:16, //字体大小
                        },
                        subtext: '课时信息'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['课次']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: xllist
                    },
                    series: [
                        {
                            name: '课次',
                            type: 'bar',
                            data: xsjelist
                        }
                    ]
                })
                window.onresize = myChart.resize
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .chartcard {
        height: 150px;
        padding: 2px;
    }
</style>
