<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                基础信息
            </div>
            <van-form>
                <van-count-down
                        ref="countDown"
                        hidden
                        second
                        time="30000"
                        :auto-start="false"
                        format="ss"
                        @change = "changetime"
                        @finish="finishok"
                />
                <van-field
                        v-model="phone"
                        name="手机号"
                        type="tel"
                        label="手机号"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                />
                <van-field
                        v-model="oldpwd"
                        name="旧密码"
                        type="password"
                        label="旧密码"
                        placeholder="请输入旧密码"
                        :rules="[{ required: true, message: '请填写旧密码' }]"
                />
                <van-field
                        v-model="sms"
                        center
                        clearable
                        label="验证码"
                        placeholder="请输入短信验证码"
                >
                    <van-button slot="button" ref="countDownBtn" size="small" :disabled="yzmabled" @touchend="getcode" @click="getcode" type="primary">
                        {{btntxt}}
                    </van-button>
                </van-field>
            </van-form>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                密码确认
            </div>
            <van-form>
                <van-field
                        v-model="loginpwd"
                        name="新密码"
                        type="password"
                        label="新密码"
                        placeholder="请输入登录密码"
                        :rules="[{ required: true, message: '请填写登录密码' }]"
                />
                <van-field
                        v-model="repwd"
                        name="确认密码"
                        type="password"
                        label="确认密码"
                        placeholder="请再次输入密码"
                        :rules="[{ required: true, message: '请填写确认密码' }]"
                />
                <div style="margin: 16px;">
                    <van-button :disabled="btnstate" round block type="info" @click="ChangePwd">
                        修改密码
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { CountDown } from 'vant';
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(CountDown);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    export default {
        name: "ChangePwd",
        data() {
            return {
                phone: '',
                sms: '',
                username: '',
                userimg: '',
                btnstate: true,
                usernick: '',
                childnick: '',
                jsname: '',
                value: '',
                jsdes: '',
                sf: '',
                sfid: '',
                oldpwd: '',
                parentname: '',
                sflist: [],
                sfidlist: [],
                childname: '',
                typeid: '',
                showPicker3: false,
                yzmabled: false,
                minDate: new Date(2000, 9, 1),
                maxDate: new Date(2020, 10, 1),
                currentDate: new Date(),
                typevalue: '',
                smscode: '',
                teacher_type_list: [],
                cbirthday: '',
                teacher_type_idlist: [],
                showPicker: false,
                repwd: '',
                loginpwd: '',
                selid: '',
                btntxt: '发送验证码'
            };
        },
        mounted: function () {
            that = this
            document.title = '修改密码'
            that.getsflist()
        },
        methods: {
            onsfConfirm (value,index) {
                window.console.log(value)
                that.sf = that.sflist[index]
                that.sfid = that.sfidlist[index]
                that.showPicker3 = false
            },
            getuserid (phone,pwd) {
                var qstr = {
                    whereinfo: " phone='" + phone + "' and loginpwd='" + pwd + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/mlogin',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length >= 1) {
                            var tmpdata = res.data[0]
                            that.selid = tmpdata.userid
                            that.btnstate = false
                        } else {
                            Notify({ type: 'warning', message: '账号错误！' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getsflist () {
                var qstr = "id > 0 and id != '99' and id != '1' and id != '2'"
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/querypi',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.sflist.push(tmpdata[i].postname)
                                that.sfidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            changetime (val) {
                this.btntxt = '等待 ' + val.seconds + ' 秒'
            },
            finishok () {
                Notify({ type: 'warning', message: '倒计时结束' });
                this.yzmabled = false
                this.btntxt = '发送验证码'
            },
            getcode () {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.phone !== '' && reg.test(this.phone)) {
                    window.console.log(this)
                    that.$refs.countDown.start()
                    this.yzmabled = true
                    var datatmp = {
                        vphone: this.phone,
                        issend: parseInt(0)
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/getsms',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                window.console.log(res)
                                that.smscode = res.data.data
                                that.sms = res.data.data
                                that.getuserid(that.phone,that.oldpwd)
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '手机号输入不正确' });
                }
            },
            ChangePwd(values) {
                window.console.log('submit', values);
                if (this.phone !== '' && this.sms !== '' && this.sms === this.smscode && this.repwd === this.loginpwd) {
                    var adddata1
                    adddata1 = {
                        phone: that.phone.toString(),
                        loginpwd: this.repwd.toString()
                    }

                    that.$http({
                        url: 'https://odapi.sqnetsoft.cn/changepwd',
                        method: 'post',
                        data: adddata1,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                Notify({ type: 'success', message: '修改成功！' });
                                that.$router.push({path: 'Login'})
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '信息不完整，请检查' });
                }

            },
            formatSecond(date) {
                return `${date.getSeconds()}`;
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            dt_select (val) {
                this.showPicker = false;
                this.cbirthday = this.formatDate(val)
            },
            onConfirm(value,index) {
                this.typevalue = value
                this.typeid = this.teacher_type_idlist[index]
                this.showPicker = false;
            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
