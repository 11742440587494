<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                <div style="flex:1;">班组信息<span v-if="bzname !== ''">({{bzname}})</span></div>
                <van-icon name="add-o" style="float:right;" @click="addxyshow" size="18" ></van-icon>
            </div>
        </div>
        <div v-for="(item,index) in xylist" :key="index" >
            <van-cell-group>
                <van-cell>
                    <template #right-icon>
                        <van-icon name="delete" @click="delxy(index)" style="font-size: 20px;" />
                    </template>
                    <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">学员名： {{item.xyname}}  <span v-if="item.phone !== null">, 电话：{{item.phone}}</span></span></div>
                </van-cell>
            </van-cell-group>
        </div>
        <div style="margin: 16px;margin-top: 60px;">
            <van-button round block type="danger" native-type="submit" @click="removebz">
                移除班组
            </van-button>
        </div>
        <van-dialog v-model="showaddxy" title="添加学员" @confirm="addxys" show-cancel-button>
            <van-search v-model="s_xyname" @search="queryxy1" placeholder="请输入搜索学员姓名" />
            <div class="van-doc-demo-block">
                <div class="van-doc-demo-block__title">
                    <div>学员信息</div>
                </div>
                <van-checkbox-group v-model="memberinfos" @change="gchange">
                    <van-cell-group>
                        <van-cell
                                v-for="(item, index) in memberlist"
                                clickable
                                :key="item.id"
                                @click="toggle(index)"
                        >
                            <template #right-icon>
                                <van-checkbox :name="item" ref="mycheckboxes" />
                            </template>
                            <template #title>
                                <span style="font-size: 13px;">学员：{{item.xyname}} <span v-if="item.phone !== null">，联系方式：{{item.phone}}</span></span>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-checkbox-group>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    var that
    export default {
        name: "BZFP",
        data () {
            return {
                bzname: '',
                s_xyname: '',
                memberlist: [],
                memberinfos: [],
                showaddxy: false,
                xylist: []
            }
        },
        mounted() {
            that = this
            that.bzname = localStorage.getItem('bzname')
            that.queryxy()
        },
        methods: {
            delxy (index) {
                window.console.log(that.xylist[index])
                var tmpdata = that.xylist[index]
                var adata = {
                    jlid: localStorage.getItem('loginid'),
                    classid: localStorage.getItem('bzid'),
                    memberid: tmpdata.memberid.toString()
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/deljcm',
                    method: 'post',
                    data:adata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.queryxy()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            addxys () {
                window.console.log(that.memberinfos)
                var adatas = []
                for (var i = 0; i < that.memberinfos.length; i++) {
                    var tmpdata = that.memberinfos[i]
                    var adata = {
                        jlid: localStorage.getItem('loginid'),
                        classid: localStorage.getItem('bzid'),
                        memberid: tmpdata.id
                    }
                    adatas.push(adata)
                }
                window.console.log(adatas)
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/addjcms',
                    method: 'post',
                    data:adatas,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.queryxy()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            gchange (values) {
                window.console.log(values)
            },
            removebz () {
                var qdata = {
                    id: localStorage.getItem('bzid')
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/delclass',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.$router.push({path: '/BZMana'})
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            addxyshow () {
                that.showaddxy = true
            },
            queryxy1 (value) {
                var qdata = {
                    whereinfo: " xyname like '%" + value + "%'",
                    limit: '',
                    sortinfo: 'order by id desc'
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/querymember',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.memberlist = res.data
                        window.console.log(that.memberlist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryxy () {
                var qdata = {
                    whereinfo: " jlid='" + localStorage.getItem('loginid') + "' and classid='" + localStorage.getItem('bzid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryclassmember',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.xylist = res.data
                        window.console.log(that.xylist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
