<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                课时上报
            </div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="tdinfo"
                    label="教练员团队"
                    placeholder="点击选择团队"
            />
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="orderdate"
                    label="上课日期"
                    placeholder="点击上课日期"
            />
            <van-cell>
                <!-- 使用 title 插槽来自定义标题 -->
                <template #extra>
                    <div style="display: flex;justify-content: center;align-items: center;flex-flow: row;">
                        <div>时间段</div>
                        <div style="margin-left: 45px;">
                            <el-time-select
                                    disabled
                                    v-model="starttime"
                                    :picker-options="{
                                start: '09:00',
    step: '00:30',
    end: '20:30'
    }"
                                    placeholder="任意时间点">
                            </el-time-select> ---
                            <el-time-select
                                    disabled
                                    arrow-control
                                    v-model="endtime"
                                    :picker-options="{
      start: '09:00',
    step: '00:30',
    end: '20:30'
    }"
                                    placeholder="任意时间点">
                            </el-time-select>
                        </div>
                    </div>
                </template>
            </van-cell>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="xq"
                    label="上课校区"
                    placeholder="点击选择校区"
            />
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="cd"
                    label="占用区域"
                    placeholder="点击选择占用区域"
            />
            <!--
            <van-field name="radio" label="占用区域占用">
                <template #input>
                    <van-radio-group v-model="cdiffull" direction="horizontal">
                        <van-radio name="0" disabled >半场训练</van-radio>
                        <van-radio name="1" disabled >整场训练</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            -->
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="kc"
                    label="课程类型"
                    placeholder="点击选择课程类型"
            />
            <!--
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="bz"
                    label="班组选择"
                    placeholder="点击选择班组选择"
            />
            -->
            <van-field
                    v-model="allcount"
                    name="在籍学员数"
                    label="在籍学员数"
                    placeholder="所有在籍学员上课人数"
            />
            <van-field
                    v-model="vipcount"
                    name="VIP人数"
                    label="VIP人数"
                    placeholder="出勤的VIP人数"
            />
            <van-field
                    v-model="normalusers"
                    name="体验人员姓名"
                    label="体验人员姓名"
                    placeholder="请填写体验人员姓名"
            />
            <van-field
                    v-model="normalcount"
                    name="体验人员人数"
                    label="体验人员人数"
                    placeholder="体验人员人数"
            />
            <van-field label="拍照凭证">
                <template #input>
                    <viewer :images="photo" >
                        <img
                                style="width:220px;height:220px;"
                                v-for="(src,index) in photo"
                                :src="src"
                                :key="index"
                        >
                    </viewer>
                </template>
            </van-field>
            <van-progress v-if="percentageType == true" :percentage="percentage" color="#3fb776" style="margin-left: 90px; margin-right:20px; margin-top: 5px; margin-bottom: 5px;"/>
            <van-field
                    readonly
                    v-model="hyimgs"
                    name="合影地址"
                    label="合影地址"
                    placeholder="合影地址"
            />
            <van-field
                    readonly
                    v-model="ksdes"
                    rows="3"
                    autosize
                    label="描述"
                    type="textarea"
                    show-word-limit
                    placeholder="备注"
            />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Progress } from 'vant';
    import { Stepper } from 'vant';
    import { Dialog } from 'vant';
    import { Checkbox, CheckboxGroup } from 'vant';

    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    Vue.use(Dialog);
    Vue.use(Stepper);
    var OSS = require('ali-oss')
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast);
    Vue.use(Progress);
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "ShowKS_Edit",
        data () {
            return {
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                showPicker5: false,
                showPicker6: false,
                showPicker7: false,
                vipusers: '',
                normalusers: '',
                photo: '',
                allcount: 0,
                vipcount: 0,
                starttime: '',
                endtime: '',
                selectpath: 'od/ks',
                memberinfos: [],
                ksdes: '',
                cdiffull: '0',
                weekid: '',
                orderdate: '',
                currentDate: '',
                tdlist: [],
                tdidlist: [],
                tdinfo: '',
                tdid: '',
                xqlist: [],
                xq: '',
                xqidlist: [],
                xqid: '',
                cdlist: [],
                cd: '',
                cdidlist: [],
                cdid: '',
                kclist: [],
                kc: '',
                kcidlist: [],
                kcid: '',
                bzlist: [],
                bz: '',
                bzidlist: [],
                bzid: '',
                memberlist: [],
                members: [],
                membersnames: '',
                percentageType: false,
                percentage: 0,
                fileurls: [],
                fileIds: [],
                fileList: [],
                upfileList: [],
                hyimgs: '',
                selno: ''
            }
        },
        mounted() {
            that = this
            that.currentDate = new Date()
            that.orderdate = that.formatDate(new Date())
            var days = that.currentDate.getDay();
            that.weekid = days
            that.getxqlist()
            that.getktlist()
            that.getclasslist()
            var tmpdata = JSON.parse(localStorage.getItem('ksshorderinfo'))
            that.selno = tmpdata.orderno
            that.orderdate = tmpdata.orderdate
            that.gettdlist(tmpdata.tdid)
            var tmptimearr = tmpdata.ordertime.split('-')
            that.starttime = tmptimearr[0]
            that.endtime = tmptimearr[1]
            that.getxqlist(tmpdata.xqid)
            that.vipusers = tmpdata.vipusers
            that.normalusers = tmpdata.novipusers
            that.allcount = tmpdata.allcount
            that.vipcount = tmpdata.vipcounts
            that.normalcount = tmpdata.rscount
            that.getcdlist(tmpdata.xqid,tmpdata.cdid)
            that.cdiffull = tmpdata.cdiffull.toString()
            that.getktlist(tmpdata.ktid)
            that.getclasslist(tmpdata.bzid)
            // that.getmemberbyids(tmpdata.memberlist,tmpdata.bzid)
            that.photo = tmpdata.cimgurl.split(',')
            that.ksdes = tmpdata.orderdes
        },
        methods: {
            bcxg () {
                window.console.log(that.memberinfos)
                var tmpdata = that.memberinfos
                var mids = ''
                var xycount = tmpdata.length
                var vipcount = 0
                for (var i = 0; i < tmpdata.length; i++) {
                    if (tmpdata[i].mcount === '-1') {
                        vipcount = vipcount + 1
                    }
                    if (tmpdata[i].memberid !== null) {
                        if (i === tmpdata.length - 1) {
                            mids = mids + tmpdata[i].memberid
                        } else {
                            mids = mids + tmpdata[i].memberid + ','
                        }
                    }
                }
                window.console.log(mids)
                window.console.log(xycount)
                window.console.log(vipcount)
                window.console.log(that.tdid)
                window.console.log(that.orderdate)
                window.console.log(that.xqid)
                window.console.log(that.cdid)
                window.console.log(that.cdiffull)
                window.console.log(that.hyimgs)
                if (that.tdid !== '' && that.tdid !== null &&
                    that.orderdate !== '' && that.orderdate !== null &&
                    that.xqid !== '' && that.xqid !== null &&
                    that.cdid !== '' && that.cdid !== null &&
                    that.cdiffull !== '' && that.cdiffull !== null &&
                    that.memberinfos.length > 0 &&
                    that.hyimgs !== '' && that.hyimgs !== null
                ) {
                    var adata = {
                        orderno: that.selno.toString(),
                        orderstate: '1',
                        tdid: that.tdid.toString(),
                        jlid: localStorage.getItem('loginid').toString(),
                        orderdate: that.orderdate.toString(),
                        weekid: that.weekid.toString(),
                        xqid: that.xqid.toString(),
                        cdid: that.cdid.toString(),
                        ordertime: that.starttime.toString() + '-' + that.endtime.toString(),
                        cdiffull: that.cdiffull.toString(),
                        subcdid: that.cdid.toString(),
                        ktid: that.kcid.toString(),
                        bzid: that.bzid.toString(),
                        rscount: xycount.toString(),
                        vipcount: vipcount.toString(),
                        memberlist: mids.toString(),
                        cimgurl: that.hyimgs.toString(),
                        orderdes: that.ksdes,
                        adddate: getNowFormatDate()
                    }
                    window.console.log(adata)
                    that.$http({
                        url: 'https://odapi.sqnetsoft.cn/addksorder',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.status === 200) {
                                Toast('修改完毕!');
                                that.$router.push({path: 'MainPage'})
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })

                }else {
                    Toast('提交信息不完整!');
                }
            },
            gchange (values) {
                window.console.log(values)
                that.members = ''
                for (var i = 0; i < values.length; i++) {
                    if (values[i].xyname !== null) {
                        if (i === values.length - 1) {
                            that.members = that.members + values[i].xyname
                        } else {
                            that.members = that.members + values[i].xyname + ','
                        }
                    }
                }
            },
            toggle(index) {
                this.$refs.mycheckboxes[index].toggle()
                window.console.log(this.$refs.mycheckboxes)
            },
            showmembers () {
                that.showPicker7 = true
            },
            gettdlist (tdid) {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/querytd',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.tdlist.push(tmpdata[i].tdname)
                                that.tdidlist.push(tmpdata[i].id)
                                window.console.log(tdid)
                                window.console.log(tmpdata[i].id)
                                if (tdid === tmpdata[i].id) {
                                    that.tdid = tmpdata[i].id
                                    that.tdinfo = tmpdata[i].tdname
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getclasslist (bzid) {
                var qstr = 'id >= 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryclass',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.bzlist.push(tmpdata[i].classname)
                                that.bzidlist.push(tmpdata[i].id)
                                if (bzid === tmpdata[i].id) {
                                    that.bz = tmpdata[i].classname
                                    that.bzid = tmpdata[i].id
                                    that.getmemberbyclass(localStorage.getItem('loginid'),that.bzid)
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxqlist (xqid) {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.xqlist.push(tmpdata[i].xqname)
                                that.xqidlist.push(tmpdata[i].id)
                                if (xqid === tmpdata[i].id) {
                                    that.xq = tmpdata[i].xqname
                                    that.xqid = tmpdata[i].id
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getktlist (ktid) {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryctype',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.kclist.push(tmpdata[i].kctype)
                                that.kcidlist.push(tmpdata[i].id)
                                if (ktid === tmpdata[i].id) {
                                    that.kc = tmpdata[i].kctype
                                    that.kcid = tmpdata[i].id
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getcdlist (xqid,cdid) {
                var qstr = 'xqid = ' + xqid
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/querycd',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.cdlist.push(tmpdata[i].cdname)
                                that.cdidlist.push(tmpdata[i].cdid)
                                if (cdid === tmpdata[i].cdid) {
                                    that.cd = tmpdata[i].cdname
                                    that.cdid = tmpdata[i].cdid
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getmemberbyids (members,bzid) {
                var qstr = " memberid in (" + members + ") and classid = '" + bzid + "' and jlid='" + localStorage.getItem('loginid') + "'"
                window.console.log(qstr)
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryclassmember',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.members = ''
                            for (var i = 0; i < tmpdata.length; i++) {
                                if (i === tmpdata.length - 1) {
                                    that.members = that.members + tmpdata[i].xyname
                                } else {
                                    that.members = that.members + tmpdata[i].xyname + ','
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getmemberbyclass (jlid,classid) {
                var qstr = ''
                if (classid === 0) {
                    qstr = " jlid = '" + jlid + "'"
                } else {
                    qstr = " jlid = '" + jlid + "' and classid='" + classid + "'"
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryksmember',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.memberinfos = tmpdata
                            that.memberlist = tmpdata
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            ontdConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker1 = false
                that.tdinfo = value
                that.tdid = that.tdidlist[index]
            },
            onbzConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker6 = false
                that.bz = value
                that.bzid = that.bzidlist[index]
                that.getmemberbyclass(localStorage.getItem('loginid'),that.bzid)
            },
            onxqConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker3 = false
                that.xq = value
                that.xqid = that.xqidlist[index]
                that.cdlist = []
                that.cdidlist = []
                that.cd = ''
                that.cdid = ''
                that.getcdlist(that.xqid,'')
            },
            oncdConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker4 = false
                that.cd = value
                that.cdid = that.cdidlist[index]
            },
            onkcConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker5 = false
                that.kc = value
                that.kcid = that.kcidlist[index]
            },
            onSJConfirm (date) {
                this.orderdate = this.formatDate(date);
                this.showPicker2 = false;
                var myDate = new Date(date);
                var days = myDate.getDay();
                if (days === 0) {
                    that.weekid = 7
                } else {
                    that.weekid = days
                }

            },
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAI5t9oHyNgxdDwTw4mnHLj',
                        accessKeySecret: 'lFA3kYzgnv7BiAmMVk7NNus7RBcnox',
                        bucket: 'sqpro'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            async fnUploadRequest (option) {
                var that = this
                if (option.file) {
                    window.console.log('单文件')
                    let file = option.file
                    const self = this
                    that.fileList[0].status = 'uploading'
                    that.fileList[0].message = '上传中'
                    this.percentageType = true;
                    return new Promise((resolve, reject) => {
                        let fileName = that.selectpath + '/' + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        window.console.log(fileName)
                        self.createOssClient().then(client => {
                            // 异步上传,返回数据
                            resolve({
                                fileName: file.name,
                                fileUrl: fileName
                            })
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    window.console.log(e.percent)
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    window.console.log(that.upfileList)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.hyimgs = that.fileurls.join(',').toString()
                                    that.fileList[0].status = 'done'
                                    that.fileList[0].message = '上传完成'
                                    return val
                                } else {
                                    that.fileList[0].status = 'failed'
                                    that.fileList[0].message = '上传失败'
                                }
                            }, err => {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                window.console.log(err)
                                window.console.log(reject)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    })
                } else {
                    window.console.log('多文件')
                    for (var i = 0; i < option.length; i++ ) {
                        window.console.log(option)
                        let file = option[i].file
                        window.console.log(that.fileList.length - option.length)
                        that.fileList[i].status = 'uploading'
                        that.fileList[i].message = '上传中'
                        const self = this
                        this.percentageType = true;
                        file.index = i
                        let fileName = that.selectpath + '/' + i + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        self.createOssClient().then(client => {
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    if (e.percent === 100) {
                                        that.fileList[file.index].status = 'success'
                                        that.fileList[file.index].message = '上传完毕'
                                    }
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.hyimgs = that.fileurls.join(',').toString()
                                    window.console.log(that.upfileList)
                                    return val
                                } else {
                                    window.console.log('上传失败')
                                }
                            }, err => {
                                window.console.log(err)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    }
                }

            }
        }
    }
    function getNowFormatDate () {
        var date = new Date()
        var seperator1 = '-'
        var seperator2 = ':'
        var month = date.getMonth() + 1
        var strDate = date.getDate()
        if (month >= 1 && month <= 9) {
            month = '0' + month
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate
        }
        var hours = date.getHours()
        if (hours >= 0 && hours <= 9) {
            hours = '0' + hours
        }
        var minutes = date.getMinutes()
        if (minutes >= 0 && minutes <= 9) {
            minutes = '0' + minutes
        }
        var seconds = date.getSeconds()
        if (seconds >= 0 && seconds <= 9) {
            seconds = '0' + seconds
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' ' + hours + seperator2 + minutes + seperator2 + seconds
        return currentdate
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 120px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
