<template>
    <div>
        <van-tabs v-model="active">
            <van-tab title="待确认课时">
                <div class="van-doc-demo-block">
                    <div class="van-doc-demo-block__title">
                        课时审核
                    </div>
                    <van-field
                            readonly
                            clickable
                            name="datetimePicker"
                            :value="sjstartvalue"
                            label="开始时间"
                            placeholder="点击选择时间"
                            @click="showPicker1 = true"
                    />
                    <van-popup v-model="showPicker1" position="bottom">
                        <van-datetime-picker
                                type="date"
                                v-model="currentDate"
                                @confirm="onSJConfirm"
                                @cancel="showPicker1 = false"
                        />
                    </van-popup>
                    <van-field
                            readonly
                            clickable
                            name="datetimePicker"
                            :value="sjendvalue"
                            label="结束时间"
                            placeholder="点击结束时间"
                            @click="showPicker2 = true"
                    />
                    <van-popup v-model="showPicker2" position="bottom">
                        <van-datetime-picker
                                type="date"
                                v-model="currentDate"
                                @confirm="onSJEndConfirm"
                                @cancel="showPicker2 = false"
                        />
                    </van-popup>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" @click="queryksorder">
                            查询课时
                        </van-button>
                    </div>
                    <div class="van-doc-demo-block__title">
                        课时列表(总数：{{kscount}})
                    </div>
                    <div v-for="(item,index) in ksorderlist" :key="index" >
                        <van-cell-group>
                            <van-cell icon=" iconfont iconliebiao" is-link value="修改" @click="showksorder(index)">
                                <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">上课日期： {{item.orderdate}} </span> <span style="margin-left: 30px; justify-content: flex-start;display: flex;width:100px;">上课人数： {{item.rscount}}</span> <span style="color: #1296db;font-size: 12px;">修改</span></div>
                                <div>时段：{{item.ordertime}}  <span style="margin-left:95px;">出勤率： {{item.rscount/item.rycount*100}}%</span></div>
                            </van-cell>
                        </van-cell-group>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已审核">
                <div class="van-doc-demo-block__title">
                    已确认课时
                </div>
                <div v-for="(item,index) in shksorderlist" :key="index" >
                    <van-cell-group>
                        <van-cell icon=" iconfont iconliebiao" is-link value="查看" @click="showshksorder(index)">
                            <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">上课日期： {{item.orderdate}} </span> <span style="margin-left: 30px; justify-content: flex-start;display: flex;width:100px;">上课人数： {{item.rscount}}</span> <span style="color: #1296db;font-size: 12px;">查看</span></div>
                            <div>时段：{{item.ordertime}}</div>
                        </van-cell>
                    </van-cell-group>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "MyKS",
        data () {
            return {
                sjstartvalue: '',
                sjendvalue: '',
                currentDate: '',
                showPicker1: false,
                showPicker2: false,
                kscount: '',
                ksorderlist: [],
                shksorderlist: []
            }
        },
        mounted() {
            that = this
            that.queryksorder()
            that.getshksorder()
            window.console.log(that)
        },
        methods: {
            onSJConfirm (date) {
                this.sjstartvalue = this.formatDate(date);
                this.showPicker1 = false;
            },
            onSJEndConfirm (date) {
                this.sjendvalue = this.formatDate(date);
                this.showPicker2 = false;
            },
            showksorder (index) {
                localStorage.setItem('kssorderinfo',JSON.stringify(that.ksorderlist[index]))
                that.$router.push({path: 'ShowKS_Edit'})
            },
            showshksorder (index) {
                localStorage.setItem('kssorderinfo',JSON.stringify(that.ksorderlist[index]))
                that.$router.push({path: 'ShowKsOrderNoSH'})
            },
            getshksorder () {
                var qdata = {
                    whereinfo: ' orderstate = 2 and jlid = ' + localStorage.getItem('loginid'),
                    limit: '',
                    sortinfo: 'order by adddate desc'
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryksorder',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.shksorderlist = res.data
                        window.console.log(that.shksorderlist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryksorder () {
                var qstr = ' orderstate = 1 and jlid = ' + localStorage.getItem('loginid')
                if (this.sjstartvalue !== null && this.sjstartvalue !== '' && this.sjendvalue !== null && this.sjendvalue !== '') {
                    qstr = qstr + " and adddate >= '" + this.sjstartvalue + " 00:00:00' and adddate<='" + this.sjendvalue + " 23:59:59'"
                }
                var qdata = {
                    whereinfo: qstr,
                    limit: '',
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryksorder',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.kscount = res.data.length
                        that.ksorderlist = res.data
                        window.console.log(that.ksorderlist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
