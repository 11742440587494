<template>
    <div>
        <van-swipe :autoplay="3000" >
            <van-swipe-item v-for="(image, index) in branddata" :key="index" style="display: flex;align-items: center;justify-content: center;">
                <img style="height: 220px;width:100%; object-fit: cover;" v-lazy="image.adurl.String" />
            </van-swipe-item>
        </van-swipe>
        <div style="background-color: #ccc;height: 10px"></div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    import { Swipe, SwipeItem } from 'vant'
    Vue.use(Swipe).use(SwipeItem)
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'MainPage',
        data () {
            return {
                active: 0,
                branddata: [],
                recommtypes: []
            }
        },
        mounted: function () {
            that = this
        },
        methods: {
            getopenid: function () {
                /*
                 window.location = "https://api.sqnetsoft.cn/wx/getgzhopenid"
                 that.$http({
                 url: 'https://api.sqnetsoft.cn/wx/getgzhopenid',
                 method: 'post',
                 }).then(function (res) {
                 window.console.log(res)
                 }).catch(function (error) {
                 window.console.log(error)
                 })
                 */
            },
            getbrand: function () {
                var qstr = 'cusid = 1'
                that.$http({
                    url: 'https://dfsd.sqnetsoft.cn/layout/getlbi',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        sorinfo: {String: ' order by tjindex', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.branddata = JSON.parse(res.data.data)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            gettjtypes: function () {
                var qstr = 'cusid = 1'
                that.$http({
                    url: 'https://dfsd.sqnetsoft.cn/layout/getti',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true},
                        sorinfo: {String: ' order by tjindex', Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.recommtypes = JSON.parse(res.data.data)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            seeall: function () {
                this.$router.push('/Type')
            }
        }
    }
</script>