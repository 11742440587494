<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                业绩详情
            </div>
            <van-cell-group>
                <van-field label="订单日期" :value="orderdate" readonly />
                <van-field label="学员" :value="xyname" readonly />
                <van-field label="性别" v-if="xysex === 1" value="男" readonly />
                <van-field label="性别" v-else value="女" readonly />
                <van-field label="监护人" :value="xypname" readonly />
                <van-field label="联系方式" :value="xyphone" readonly />
                <van-field label="课次" :value="ccount" readonly />
                <van-field label="金额" :value="cprice" readonly />
                <van-field label="收款方式" :value="payway" readonly />
                <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="xq"
                        label="所属校区"
                />
                <van-popup v-model="showPicker3" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="xqlist"
                    />
                </van-popup>
                <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="jl"
                        label="任课教练"
                />
                <van-popup v-model="showPicker4" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="jllist"
                    />
                </van-popup>
                <van-field label="介绍人" :value="jxrname" readonly />
                <van-field label="缴费类别" v-if="xftype === 1" value="新生" readonly />
                <van-field label="缴费类别" v-else-if="xftype === 2" value="续费" readonly />
                <van-field label="缴费类别" v-else value="集训" readonly />
                <van-field label="支付截图">
                    <template #input>
                        <viewer :images="photo" >
                            <img
                                    style="width:220px;height:220px;"
                                    v-for="(src,index) in photo"
                                    :src="src"
                                    :key="index"
                            >
                        </viewer>
                    </template>
                </van-field>
                <van-field
                        v-model="fkdes"
                        rows="3"
                        autosize
                        label="描述"
                        type="textarea"
                        show-word-limit
                        placeholder="备注"
                />
                <van-field label="队服尺码" :value="dfcm" readonly />
                <van-field label="队服数量" :value="dfcount" readonly />
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Image as VanImage } from 'vant';

    Vue.use(VanImage);
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "ShowOrder",
        data () {
            return {
                showPicker3: false,
                showPicker4: false,
                jl:'',
                dfno: '',
                dfcm: '',
                dfcount: '',
                xysg: '',
                xyage: '',
                xq: '',
                xyname: '',
                orderdate: '',
                photo: '',
                xysex: '',
                xypname: '',
                xftype: '',
                xyphone: '',
                ccount: '',
                cprice: '',
                payway: '',
                fkdes: '',
                orderid: '',
                xqlist: [],
                xqid: '',
                xqidlist: [],
                jllist: [],
                jlid: '',
                jlidlist: [],
                jxrname: '',
                xytype: '',
                fkimg: ''
            }
        },
        mounted() {
            var tmpdata = JSON.parse(localStorage.getItem('sorderinfo'))
            this.xyname = tmpdata.xyname
            this.xysex = tmpdata.xysex
            this.orderdate = tmpdata.orderdate
            this.xypname = tmpdata.xypname
            this.xyphone = tmpdata.xyphone
            this.ccount = tmpdata.ccount
            this.cprice = tmpdata.cprice
            this.payway = tmpdata.payway
            this.jxrname = tmpdata.jxrname
            this.xftype = tmpdata.xftype
            this.xytype = tmpdata.xytype
            this.photo = tmpdata.fkimg.split(',')
            this.fkdes = tmpdata.fkdes
            this.orderid = tmpdata.id
            if(tmpdata.fzno === 0){
                this.dfno = ''
            } else {
                this.dfno = tmpdata.fzno
            }
            this.dfcm = tmpdata.fzcm
            if(tmpdata.fzcount === 0){
                this.dfcount = ''
            } else {
                this.dfcount = tmpdata.fzcount
            }
            if(tmpdata.xysg === 0){
                this.xysg = ''
            } else {
                this.xysg = tmpdata.xysg
            }
            if(tmpdata.xyage === 0){
                this.xyage = ''
            } else {
                this.xyage = tmpdata.xyage
            }
            that = this
            that.getxqlist(tmpdata.xqid)
            that.getjllist(tmpdata.jlid)
            document.title = '业绩信息'
        },
        methods: {
            getxqlist (index) {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.xqlist.push(tmpdata[i].xqname)
                                that.xqidlist.push(tmpdata[i].id)
                            }
                            that.xq = that.xqlist[index - 1]
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getjllist (index) {
                var qstr = "userid > 0 and postname like '%教练%'"
                that.$http({
                    url: 'https://odapi.sqnetsoft.cn/queryjlinfo',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.jllist.push(tmpdata[i].realname)
                                that.jlidlist.push(tmpdata[i].userid)
                                if (index === tmpdata[i].userid) {
                                    that.jl = tmpdata[i].realname
                                    that.jlid = tmpdata[i].userid
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
